<template>
	<div id="div">
		<img class="img_bg" src="../../asset/vip/img_01.jpg" alt="img_bg" />

		<div class="marquee_box">
			<div class="marquee_list" :class="{marquee_top:animate}">
				<div class="li" v-for="(item, index) in marqueeList" :key="index"
					:style="{background: item.background}">
					<div class="li_img">
						<img :src="item.src" alt="">
						<div class="li_name">{{item.Tname}}</div>
						<div class="li_text">{{item.quantity}}词</div>
					</div>
					<div class="li_n">{{item.Tname}}</div>
					<div class="li_t">定价：¥{{item.price}}</div>
				</div>
			</div>
			<img class="img_bg" src="../../asset/vip/img_02.jpg" />
		</div>
		<img class="img_bg" src="../../asset/vip/img_03.jpg" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/img_04.jpg" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/img_05.jpg" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/img_06.jpg" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/img_07.jpg" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/img_08.jpg" alt="img_bg" />
		<img class="img_bg" src="../../asset/vip/img_09.jpg" alt="img_bg" />
		<div class="btn_vi">
			<!-- @click="onClick(3)" wexinPay-->
			<div class="btn01" @click="onClick(3)">
				<div class="he">刷题会员半年卡·¥98
				</div>
				<div class="text">为学习花点钱总是值得的</div>
			</div>
			<div class="btn02" @click="onClick(4)">
				<div class="he">刷题会员年卡·¥188
				</div>
				<div class="text">低至0.5元/天</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Notify,
		Cell,
		CellGroup,
		Field,
		Icon,
		Loading,
	} from 'vant';
	 import wx from "weixin-js-sdk";
	import https from '../../https.js'
	export default {
		components: {
			[Notify.name]: Notify,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Field.name]: Field,
			[Icon.name]: Icon,
			[Loading.name]: Loading,
		},
		data() {
			return {
				animate: false,
				orderResult: '',
				result: "",
				url:"",
				marqueeList: [{
						Tname: "高频核心词",
						quantity: 481,
						price: "6",
						src: require("../../asset/vip/cs_01.png")
					},
					{
						Tname: "冲刺佛脚词",
						quantity: 227,
						price: "6",
						src: require("../../asset/vip/cs_02.png")
					},
					{
						Tname: "同义替换词",
						quantity: 530,
						price: "6",
						src: require("../../asset/vip/cs_03.png")
					},
					{
						Tname: "熟词僻义词",
						quantity: 173,
						price: "6",
						src: require("../../asset/vip/cs_04.png")
					},
					{
						Tname: "必备形近词",
						quantity: 470,
						price: "6",
						src: require("../../asset/vip/cs_05.png")
					},
					{
						Tname: "写作必备词",
						quantity: 240,
						price: "6",
						src: require("../../asset/vip/cs_06.png")
					},
				]
			}
		},
		mounted: function() {
		},
		created: function() {
			this.showMarquee()
			setInterval(() => {
				this.showMarquee()
			}, 3000);
		},
		methods: {
			showMarquee: function() {
				this.animate = true;
				setTimeout(() => {
					this.marqueeList.push(this.marqueeList[0]);
					this.marqueeList.shift();
					this.animate = false;
				}, 2500);
			},
			onClick(e) {
				let u = navigator.userAgent;
				let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
				if (isAndroid) {
					window.android.payment(e)
				} else {
					window.webkit.messageHandlers.payment.postMessage(e)
				}
			},
			parseUrl(url) {
				var result = {};
				var queryArr = url.split("&");
				queryArr.forEach(function(item) {
					var obj = {};
					var value = item.split("=")[1];
					var key = item.split("=")[0];
					result[key] = value;
				});
				return result;
			}
		}
	}
</script>

<style scoped="scoped">
	div,
	ul,
	li,
	span,
	img {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		display: block;
	}

	.btn_vi {
		display: flex;
		text-align: center;
		align-items: flex-end;
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	.btn_vi>div {
		width: 50%;
		letter-spacing: 2px;
	}

	.btn01 {
		background-image: url(../../asset/vip/btn01.png);
		height: 110px;
		background-size: 100% 100%;
		padding-top: 22px;
		color: #fff;
	}

	.btn01 .he {
		font-size: 28px;
		font-weight: bold;
		line-height: 28px;
		letter-spacing: 1px;
	}

	.btn01 .text {
		font-size: 22px;
		margin-top: 17px;
		line-height: 22px;

	}

	.btn02 {
		background-image: url(../../asset/vip/btn02.png);
		height: 140px;
		background-size: 100% 100%;
		padding-top: 29px;
		color: #fff;
	}

	.btn02 .he {
		font-size: 30px;
		font-weight: bold;
		line-height: 30px;
		color: #261C00;

	}

	.btn02 .text {
		font-size: 26px;
		margin-top: 17px;
		line-height: 22px;
		padding: 6px 20px;
		background-color: #FF510D;
		display: inline-block;
		border-radius: 0 15px 0 15px;
	}

	.img_bg {
		width: 100%;
	}

	.marquee_box {
		display: block;
		position: relative;
		width: 100%;
		height: 360px;
		overflow: hidden;
		margin: 0 auto;
	}

	.marquee_list {
		display: flex;
		align-items: center;
		width: 800px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.marquee_top {
		transition: all 2s linear;
		margin-left: -188px;
	}

	.marquee_list .li {
		/* height: 230px; */
		width: 140px;
		line-height: 30px;
		font-size: 14px;
		color: #fff;
		margin: 0 24px;
	}

	.li_img {
		position: relative;
	}

	.li_name {
		position: absolute;
		width: 100%;
		top: 42px;
		text-align: center;
		font-size: 22px;
		line-height: 22px;
		font-weight: bold;
	}

	.li_text {
		position: absolute;
		width: 100%;
		top: 91.5px;
		text-align: center;
		font-size: 20px;
		line-height: 20px;
	}

	.li_n {
		margin-top: 17px;
		text-align: center;
		font-size: 22px;
		line-height: 22px;
		font-weight: bold;
	}

	.li_t {
		margin-top: 11px;
		text-align: center;
		font-size: 20px;
		line-height: 20px;
	}

	.marquee_list .li img {
		height: 230px;
		width: 140px;
		display: block;
		height: auto;
	}
</style>
